import {AccountExternal, CreditReportDetails} from "../../../declerations/server/account_models";
import {UserBase, UserExternal} from "../../../declerations/server/user_models";
import {Address, Email, Phone} from "../../../declerations/server";
import {find, isEmpty, map} from "lodash";
import {FileRead} from "@bounce/shared/declarations";
import {get} from "lodash";
import {Theme} from "@mui/material/styles";
import {ActivePaymentPlan} from "../../../declerations/server/payment_plan_models";
import {Colors} from "@bounce/shared/ui";
import {getDaysUntilCreditReport} from "../../../helpers/CreditReportHelper";
import {InputUtils} from "@bounce/shared/helpers";
export const accountFlagsTypes = [
  {value: "active_complaint", label: "Active Complaint"},
  {value: "creditor_recalled", label: "Creditor Recalled"},
  {value: "closed", label: "Closed"},
  {value: "fraud_reported", label: "Fraud Reported"},
  {value: "cease_and_desist", label: "Cease And Desist"},
  {value: "freeze_communication", label: "Freeze Communication"},
];

export const userFlagsTypes = [
  {value: "hearing_disability", label: "Hearing Disability"},
  {value: "reading_disability", label: "Reading Disability"},
  {value: "active_military", label: "Active Military"},
  {value: "deceased", label: "Deceased"},
  {value: "unhandled_communication", label: "Unhandled Communication"},
];

export const unEditableAccountFlagsTypes = [
  {value: "represented", label: "Represented"},
  {value: "statute_barred", label: "Statute Barred"},
];

export type FlagType = {value: string; label: string};

interface IGetEnabledFlags {
  data: AccountExternal | UserExternal;
  flagsTypes: FlagType[];
}

interface IGetUserFullNameByNames {
  firstName: string;
  middleName?: string;
  lastName: string;
}

interface IGetAccountCardLabel {
  theme: Theme;
  inDispute: boolean;
  creditReportDetails?: CreditReportDetails;
  represented?: boolean;
  paymentPlan?: ActivePaymentPlan;
  isCreditRecalled?: boolean;
  isClosed?: boolean;
  isAgent: boolean;
  isEligibleReactivatePlan: boolean;
}

interface AccountCardLabel {
  label: string;
  color: string;
  action?: "manage" | "resolve";
  progressBarColor?: string;
}

export const getUserFullName = (user: UserBase) => {
  const firstName = user?.first_name;
  const middleName = user?.middle_name ? " " + user?.middle_name + " " : " ";
  const lastName = user?.last_name;
  return InputUtils.prettierSnakeCase(firstName + middleName + lastName);
};

export const getUserFullNameByNames = ({firstName, middleName, lastName}: IGetUserFullNameByNames) => {
  const getMiddleName = middleName ? middleName + " " : "";
  return firstName + " " + getMiddleName + lastName;
};

export const getDefaultPhone = (userPhones?: Array<Phone>): string => {
  if (!userPhones || isEmpty(userPhones)) return "";
  const primary = find(userPhones, (phone: Phone) => phone.is_primary);
  return primary ? primary.phone : userPhones[0].phone;
};

export const getDefaultAddress = (userAddresses?: Array<Address>): string => {
  if (!userAddresses || isEmpty(userAddresses)) return "";
  const primary = find(userAddresses, (address: Address) => address.is_primary);
  if (primary) {
    return primary.address || "";
  } else {
    return userAddresses[0].address || "";
  }
};
export const getDefaultEmail = (userEmails?: Array<Email>): string => {
  if (!userEmails || isEmpty(userEmails)) return "";
  const primary = find(userEmails, (email: Email) => email.is_primary);
  return primary ? primary.email : userEmails[0].email;
};

export const getUserFilesOptions = (
  files?: Array<FileRead>,
  default_file_ids?: Array<string>
): Array<{value: string; label: string}> => {
  if (isEmpty(files)) return [];
  return map(files, (file) => ({value: file.id, label: file.filename}));
};

const flagEnabled = (data: AccountExternal | UserExternal, flagName: string): string => {
  const flag = get(data, flagName);
  if (flag?.hasOwnProperty("data")) {
    return flag.data;
  }
  return flag;
};
export const getEnabledFlags = ({data, flagsTypes}: IGetEnabledFlags) => {
  const enabledFlagLabels: FlagType[] = [];
  Object.values(flagsTypes).forEach((flagName) => {
    if (flagEnabled(data, flagName.value)) {
      const flagType = flagsTypes.find((flag: {value: string; label: string}) => flag.value === flagName.value);
      if (flagType) {
        enabledFlagLabels.push({value: flagType.value, label: flagType.label});
      }
    }
  });
  return enabledFlagLabels;
};

export const getAccountCardLabel = ({
  theme,
  inDispute,
  creditReportDetails,
  represented,
  paymentPlan,
  isAgent,
  isCreditRecalled,
  isClosed,
  isEligibleReactivatePlan,
}: IGetAccountCardLabel): AccountCardLabel => {
  const daysUntilReport = getDaysUntilCreditReport(creditReportDetails);
  const inRiskOfCreditReport = !!(daysUntilReport && daysUntilReport <= 30);
  if (inDispute) {
    return {
      label: "Dispute filed",
      color: isAgent ? Colors.red._700 : Colors.bw.white,
      action: "resolve",
      progressBarColor: Colors.grey._500,
    };
  }
  if (isClosed) {
    return {
      label: "Account Closed",
      color: isAgent ? Colors.orange._800 : Colors.red._100,
      action: "resolve",
    };
  }
  if (isCreditRecalled) {
    return {
      label: "Returned to creditor",
      color: isAgent ? Colors.orange._800 : Colors.red._100,
      action: "resolve",
    };
  }
  if (inRiskOfCreditReport) {
    return {
      label: "At risk of a credit report",
      color: isAgent ? Colors.orange._800 : Colors.red._100,
      action: "resolve",
    };
  }
  if (represented && !paymentPlan && isAgent) {
    return {
      label: "Represented",
      color: isAgent ? theme.palette.text.label : Colors.red._200,
      action: "resolve",
    };
  }
  if (isEligibleReactivatePlan) {
    return {
      label: "Canceled plan",
      color: isAgent ? Colors.red._900 : Colors.red._100,
      action: "resolve",
    };
  }
  if (!paymentPlan || (paymentPlan.is_interim_plan && paymentPlan.state === "resolved")) {
    return {
      label: "Pending plan",
      color: isAgent ? Colors.grey._500 : Colors.bw.white,
      action: "resolve",
    };
  }
  if (paymentPlan.late_installment) {
    return {
      label: "Payment issue",
      color: isAgent ? Colors.red._900 : Colors.red._100,
      action: "resolve",
      progressBarColor: Colors.grey._500,
    };
  }
  if (paymentPlan.state === "resolved") {
    return {
      label: "Account resolved",
      color: isAgent ? Colors.green._900 : Colors.green._200,
      progressBarColor: Colors.green._900,
    };
  }
  if (paymentPlan.is_interim_plan) {
    return {
      label: "Temporary plan",
      color: isAgent ? Colors.orange._800 : Colors.green._200,
      action: "manage",
      progressBarColor: Colors.green._900,
    };
  }
  return {
    label: "Active plan",
    color: isAgent ? Colors.green._900 : Colors.green._100,
    action: "manage",
    progressBarColor: Colors.green._900,
  };
};

export const copyToClipboard = (textToCopy: string, isMobile: boolean) => {
  if (isMobile) {
    navigator.clipboard.writeText(textToCopy);
  } else {
    const input = document.createElement("textarea");
    input.value = textToCopy;
    document.body.prepend(input);
    input.select();
    input.focus();
    document.execCommand("copy");
    document.body.removeChild(input);
  }
};
